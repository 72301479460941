import * as React from "react";
import {
  ChakraProvider,
  Box,
  Link,
  VStack,
  Grid,
  theme,
  Wrap,
  WrapItem,
  Image,
  Text
} from "@chakra-ui/react";
import logo1 from "./21st Cent thumbnail 01 cross stitch.jpg";
import logo2 from "./21st Cent thumbnail 01 print.jpg";
import logo3 from "./21st Cent thumbnail digital.jpg";
import logo4 from "./Moloch compressed.jpg";
import logo5 from "./IRL Art compressed.jpg";
import logo6 from "./Crazy Chicken compressed.jpg";
import logo7 from "./MetaFactory 01.jpeg";
import logo8 from "./FireEyes 01.jpeg";
import logo9 from "./MetaCartel 03.jpeg";
import logo10 from "./LisCon 2021 03 compressed.jpg";
import logo11 from "./Santiago Compressed.jpg";
import logo12 from "./LisCon 2021 05 compressed.jpg";

const images1 = [{ "file": logo1, "link": "https://doingud.com/creation/0x0a9dfc615f7fd8356ed2dead6868c740629e2065000000000000", "words": "Cross-Stitch" }, { "file": logo2, "link": "http.example.com", "words": "Physical Print" }, { "file": logo3, "link": "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/4802265340254348071393535365625234212443018895687367378946229214633133080704", "words": "Digital Design" }];
const images2 = [{ "file": logo4, "link": "https://doingud.com/creation/0x0a9dfc615f7fd8356ed2dead6868c740629e2065000000000006", "words": "Moloch Soldier" }, { "file": logo5, "link": "https://doingud.com/creation/0x0a9dfc615f7fd8356ed2dead6868c740629e2065000000000013", "words": "IRL Art" }, { "file": logo6, "link": "https://doingud.com/creation/0x0a9dfc615f7fd8356ed2dead6868c740629e206500000000000b", "words": "Pollo Loco" }];
const images3 = [{ "file": logo7, "link": "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/58253972863828557348813111083557034724280287676467778914124160763961238618113", "words": "MetaFactory 01" }, { "file": logo8, "link": "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/58253972863828557348813111083557034724280287676467778914124160760662703734785", "words": "Fire Eyes DAO 02" }, { "file": logo9, "link": "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/58253972863828557348813111083557034724280287676467778914124160769458796756993", "words": "MetaCartel 03" }];
const images4 = [{ "file": logo10, "link": "https://doingud.com/creation/0x0a9dfc615f7fd8356ed2dead6868c740629e2065000000000014", "words": "LisCon 03" }, { "file": logo11, "link": "https://doingud.com/creation/0x0a9dfc615f7fd8356ed2dead6868c740629e206500000000001e", "words": "Santiago The Stingray" }, { "file": logo12, "link": "https://doingud.com/creation/0x0a9dfc615f7fd8356ed2dead6868c740629e2065000000000015", "words": "LisCon 05" }];

export const App = () => (
  <ChakraProvider theme={theme}>
    <Box textAlign="center" fontSize="xl">


      <Text
        fontSize='6xl'
        color="#0000FF"
      >21st Century Prospectors</Text>
      
      <Grid minH="100vh" p={3}>
        <VStack spacing={8}>
          <Wrap>
            {images1.map((image) => {
              return (
                <WrapItem>
                  <VStack spacing={8}>
                    <Link
                      color="#FF00FF"
                      href={image.link}
                      fontSize="2xl"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Image src={image.file} boxSize="sm" objectFit="cover" />
                      <Text fontSize='md'>{image.words}</Text>
                    </Link>
                  </VStack>
                </WrapItem>
              );
            })}
          </Wrap>



          <Text
            fontSize='6xl'
            color="#00FF00"
          >ETHDenver 2022 Frens</Text>
          <Wrap>
            {images2.map((image) => {
              return (
                <WrapItem>
                  <VStack spacing={8}>
                    <Link
                      color="#FF00FF"
                      href={image.link}
                      fontSize="2xl"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Image src={image.file} boxSize="sm" objectFit="cover" />
                      <Text fontSize='md'>{image.words}</Text>
                    </Link>
                  </VStack>
                </WrapItem>
              );
            })}
          </Wrap>

          <Text
            fontSize='6xl'
            color="#00FFFF"
          >MCON 2021</Text>

          <Wrap>
            {images3.map((image) => {
              return (
                <WrapItem>
                  <VStack spacing={8}>
                    <Link
                      color="#FF00FF"
                      href={image.link}
                      fontSize="2xl"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Image src={image.file} boxSize="sm" objectFit="cover" />
                      <Text fontSize='md'>{image.words}</Text>
                    </Link>
                  </VStack>
                </WrapItem>
              );
            })}
          </Wrap>

          <Text
            fontSize='6xl'
            color="#000000"
          >LisCon 2021</Text>

          <Wrap>
            {images4.map((image) => {
              return (
                <WrapItem>
                  <VStack spacing={8}>
                    <Link
                      color="#000000"
                      href={image.link}
                      fontSize="2xl"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Image src={image.file} boxSize="sm" objectFit="cover" />
                      <Text fontSize='md'>{image.words}</Text>
                    </Link>
                  </VStack>
                </WrapItem>
              );
            })}
          </Wrap>

          <Link
            color="magenta"
            href="https://twitter.com/HungryDAOJones"
            fontSize="2xl"
            target="_blank"
            rel="noopener noreferrer"
          >
            @HungryDAOJones
          </Link>

        </VStack>
      </Grid>
    </Box>
  </ChakraProvider>
);
